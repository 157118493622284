<template>
  <sub-page title="Оновлення"
             :icon="mdi-update"
  >

    <template #header.action>
      <ws-button v-if="!displayDialog" @click="openNewUpdate" label="Add"/>
      <ws-button v-else-if="entityData.value && !entityData.is_published" @click="openPublishUpdate" outlined class="mr-2" label="Publish"/>
      <ws-button v-if="displayDialog" @click="addEditUpdate" :label="newEntity ? 'Add' : 'Save'"/>
    </template>

<!--    $router.push(localeLink('dashboard/support/updates/' + item.uuid ))-->

    <template #default>
      <ws-data-table
          v-if="!displayDialog"
          :items="items"
          :headers="headers"
          :row-action="openEditUpdate"
          class="mt-6"
      >
        <template #item.date="{item}">
          <h5 class="wsDARKLIGHT text-no-wrap"> {{ MONTH_DAY_TIME(item.date , false , true) }}</h5>
        </template>

        <template #item.text="{item}">
          <h5 style="min-width: 200px" :class="[{wsACCENT: item.text !== ' '}, {'font-weight-regular' : item.text === ' '}, {wsDARKLIGHT : item.text === ' '}]">
            {{ item.text !== ' ' ? item.text : 'Без назви'}}
          </h5>
        </template>

        <template #item.type="{item}">
          <h5 v-if="item.type" class="wsACCENT text-no-wrap"> {{ $t(`support.updates.types.${item.type}`) }}  </h5>
        </template>



        <template #item.to_newsletter="{item}">
          <div>
            <v-icon v-if="item.to_newsletter" :color="wsACCENT">mdi-checkbook-arrow-right</v-icon>
          </div>

        </template>

        <template #item.is_published="{item}">
          <v-icon :color="!item.is_published ? wsBACKGROUND : wsSUCCESS">mdi-check-circle-outline</v-icon>
        </template>


      </ws-data-table>
      <div v-if="displayDialog">
        <ws-button @click="displayDialog = false" text left-icon="mdi-chevron-left" class="mt-5" label="Back" />
        <ws-accordion
            class="mt-6 "
            :items="editUpdateNavigation"
        >
          <template #action.text>
            <ws-text-field
                v-model="entityData.text"
                placeholder="Введіть назву оновлення"
                width="50%"
                class="mr-6 mb-1"
                flat

            />
          </template>

          <template #action.type>
            <ws-select
                v-model="entityData.type"
                :items="typesSelect"
                placeholder="Оберіть тип оновлення"
                class="mr-6"
                width="250px"
                hide-details
            />
          </template>

          <template #action.date>
            <ws-date-picker
                v-model="entityData.date"
                placeholder="Сьогоднішня"
                class="mr-6"
                time-stamp
            />
          </template>

          <template #action.to_newsletter>
            <ws-switch
                v-model="entityData.to_newsletter"
                class="mr-6 mb-n4 mt-n3"
            />
          </template>

          <template #item.image>
            <div  v-if="entityData.img" class="d-flex pa-4">
              <v-sheet width="300"  :color="wsLIGHTCARD" class="mt-4 wsRoundedHalf overflow-hidden">
                <v-img
                    width="300"
                    height="230"
                    containt
                    :src="entityData.img || null"
                    transition="xxx"
                />
                <div class="pa-2 d-flex justify-space-between align-center shorten-text">
                  <div class="shorten-text">
                    <h5 class="shorten-text wsACCENT">{{ entityData.img }}</h5>
                  </div>
                  <v-btn @click="entityData.img = null;entityData = COPY(entityData)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                </div>
              </v-sheet>

            </div>
            <ws-file-uploader
                v-else
                superadmin public
                @success="($event) => { notify($event); entityData.img = $event.url; entityData = COPY(entityData)}"
            ></ws-file-uploader>



          </template>

          <template #item.content>
            <v-sheet>
              <ws-text-editor
                  v-model="entityData.content"
                  placeholder="Введіть опис оновлення"
                  is-public
                  superadmin
              />
            </v-sheet>
          </template>

        </ws-accordion>
        <v-sheet  height="124" ></v-sheet>
      </div>

    </template>

    <template #dialog>
      <ws-dialog
          v-model="displayPublishDialog"
          title="Опублікувати оновлення"
          @save="publishUpdate"
          blank
          no-padding
          width="528"
      >

        <v-sheet :color="wsATTENTION" dark class="pa-4">
          <div class="d-flex align-center pr-2">
            <v-icon  class="ml-n2"  color="#ffffff">mdi-chevron-left</v-icon>
            <h4 style="color : #ffffff" class="shorten-text">{{ entityData.text || $t(`support.updates.types.${entityData.type}`) }}</h4>
          </div>
        </v-sheet>

        <v-sheet :color="wsLIGHTCARD">
          <v-img
              v-if="entityData.img"
              :height="!SM ? '334' : '200'"
              :src="entityData.img || null"
              transition="xxx"
          />
          <v-sheet v-else class="d-flex justify-center"  :height="!SM ? '334' : '200'">
            <img
                :height="!SM ? '334' : '200'"
                width="auto"
                src="@/assets/img/art/updates.png"
            />
            <!--        -->
          </v-sheet>

          <v-sheet class="mx-auto pt-4" color="transparent" max-width="448" width="100%">

            <!-- Date, Reading time , socials -->
            <h5 class="wsDARKLIGHT font-weight-regular mb-4">
              {{ $t(`support.updates.types.${entityData.type}`) }} · {{ PARSE_DATE(entityData.date , false ,true).toLowerCase() }}
            </h5>
            <!-- Title -->
            <h3 style=" line-height: 1.2; "
                :style="`color : ${wsDARKER};`"
                class="mb-4">
              {{ entityData.text }}
            </h3>

            <ws-text-viewer
                class="mt-5"
                :value="entityData.content"
                font-size="16"
                is-public
                video-height="248"
            />
          </v-sheet>
        </v-sheet>

        <template #footer>
          <v-sheet
              :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLight  "
          >
            <h5 v-if="entityData.to_newsletter" class="d-flex py-3 px-5 align-center font-weight-regular text-center">
              <v-icon :color="wsATTENTION" class="mr-2">mdi-information</v-icon>Після публікації буде запущено Email розсилку
            </h5>

            <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>

            <div class="d-flex justify-space-between pt-4 pb-4 px-5">
              <ws-button :disabled="LOADING" @click="displayPublishDialog = false" class="mr-3" label="Cancel" outlined></ws-button>
              <ws-button :loading="LOADING" @click="publishUpdate" label="Publish"></ws-button>
            </div>



          </v-sheet>
        </template>


      </ws-dialog>
    </template>


  </sub-page>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "DashUpdates",
  data() {
    return {
      items : [],
      displayDialog : false,
      displayPublishDialog : false,
      entityData : {
        content : []
      },
      newEntity : true,
      selectedLang : 'ua',
      editUpdateNavigation : [
        { name : 'Назва',
          subtitle : 'Назва оновлення, можливо залишити пустим',
          icon : 'mdi-cog',
          value : 'text',
          noExpand : true,
        },
        { name : 'Тип',
          subtitle : 'Тип оновлення',
          icon : 'mdi-cog',
          value : 'type',
          noExpand : true,
        },
        { name : 'Дата',
          subtitle : 'Дата, коли опубліковано оновлення',
          icon : 'mdi-cog',
          value : 'date',
          noExpand : true,
        },
        { name : 'Надіслати розсилку',
          subtitle : 'Чи потрібно робити Email розсилку після публікації оновлення',
          value : 'to_newsletter',
          icon : 'mdi-checkbook-arrow-right',
          noExpand : true,
        },
        { name : 'Зображення',
          subtitle : 'Зображення оновлення, можна залишити пустим',
          value : 'image',
          icon : 'mdi-image',
        },
        { name : 'Зміст',
          subtitle : 'Опис оновлення',
          value : 'content',
          icon : 'mdi-checkbook-arrow-right',
        }
      ]
    }
  },
  computed : {
    typesSelect() {
      return [
        { text : this.$t(`support.updates.types.current`) , value : 'current' },
        { text : this.$t(`support.updates.types.new_function`) , value : 'new_function' },
        { text : this.$t(`support.updates.types.interface`) , value : 'interface' },
        { text : this.$t(`support.updates.types.security`) , value : 'security' }
    ]
    },
    headers() {
      return [
        { text : 'Дата' , value : 'date' , width : 10 },
        { text : 'Назва' , value : 'text' },
        { text : 'Tип' , value : 'type' , width : 10},
        { text : 'До розсилки' , value : 'to_newsletter' , width : 10  },
        { value : 'is_published' , width : 10 , sortable : false }
      ]
    }
  },
  methods : {
    ...mapActions('support',[
        'ADD_EDIT_UPDATE',
        'GET_UPDATE_ADMIN',
        'GET_UPDATES_ADMIN',
        'PUBLISH_UPDATE'
    ]),
    async openPublishUpdate() {
      await this.addEditUpdate(false)
      this.displayPublishDialog = true
    },
    async publishUpdate() {

      let result = await this.PUBLISH_UPDATE(this.entityData.value)

      if ( !result ) {
        return this.ERROR(this.$store.state.ajax.error)
      }
      this.notify('Оповіщення про оновлення опубліковано' , 'success')
      this.entityData.is_published = true
      this.entityData = this.COPY(this.entityData)

      let index = this.items.findIndex(el => el.value === this.entityData.value)
      if ( index === -1 ) {
        return
      }
      this.items[index] = this.COPY(this.entityData)
      this.items = this.COPY(this.items)

      this.displayPublishDialog = false


    },
    async openNewUpdate() {
      this.newEntity = true
      this.entityData = {
        content : []
      }
      this.displayDialog = true
    },
    async openEditUpdate(item) {
      this.newEntity = false
      this.entityData = await this.GET_UPDATE_ADMIN({uuid : item.value , lang : this.selectedLang})
      this.displayDialog = true
    },


    async addEditUpdate(notification = true) {
      this.entityData.lang = this.selectedLang
      let result = await this.ADD_EDIT_UPDATE(this.entityData)
      if ( !result || result === true) {
        return
      }
      if ( this.newEntity ) {
        this.items.unshift(result)
      }

      if ( this.newEntity  ) {
        this.displayDialog = false
      } else {
        if ( notification ) {
          this.notify('Зміни збережено')
        }
        let index = this.items.findIndex(el => el.value === this.entityData.value)
        if ( index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
      }


    },
  },


  async mounted() {

    let result = await this.GET_UPDATES_ADMIN()
    if ( !result || result === true) {
      return
    }
    this.items = result
  }
}
</script>

<style scoped>

</style>